import { ContentfulProvider, GeminiProvider } from "@/services/providers";
import { PageData, ComponentData } from "@/renderers";
import { QueryParams } from "@/libs/QueryParams";
import { SITE_TITLE } from "@/services/libs";
import { Service, ServiceArgs } from "@/services/";
import {
  PATHS,
  getIdFromSlug,
  maybeCreatePaywall,
  stripUndefinedValuesFromObject,
} from "@/services/libs";

import { Authentication } from "@/libs";
import {
  ComponentMapper,
  SectionDataMapper,
} from "@/services/providers/contentful/mappers";
import { ReportShowMapper } from "./report_show_mapper";
import { Report } from "@/services/providers/contentful/types";
import { UserAction, userHasAction } from "@/libs/authentication/user";

const retrievePageData = async (
  serviceArgs: ServiceArgs
): Promise<PageData | null> => {
  const { path, accessToken, userActions } = serviceArgs;
  const id = getIdFromSlug(path);
  const report: Report | undefined =
    await ContentfulProvider.getReportContentById(id);

  report &&
    userActions &&
    accessToken &&
    maybeTrackReportView(report, userActions, accessToken);

  const relatedQuery = `content_type=editorials&categoryId=${report?.editorialCategory?.sys?.id}`;
  const relatedEditorial = await GeminiProvider.getFilteredContent(
    QueryParams.parseSearchQuery(relatedQuery),
    accessToken
  );

  if (!report) {
    return null;
  }

  const result = {
    title: `${report.title} | Reports | ${SITE_TITLE}`,
    metaDescription: report.description,
    slug: `${PATHS.reports}/${report.id}`,
    cannonicalUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${PATHS.reports}/${report?.slug}`,
    metaTitle: report.title,
    metaImage: { url: report.thumbnail?.url },
    components: await buildComponents(report, relatedEditorial, serviceArgs),
  };
  return stripUndefinedValuesFromObject(result);
};

const buildComponents = async (
  report: Report,
  relatedEditorial: any,
  serviceArgs: ServiceArgs
): Promise<ComponentData[]> => {
  const { path, userActions, accessToken } = serviceArgs;

  const canDownload =
    Authentication.userHasAction(
      Authentication.USER_ACTIONS.ASSETS_DOWNLOAD,
      userActions
    ) || false;

  return [
    maybeCreatePageTitleBlock(report, canDownload),
    maybeCreateHeaderComponent(report),
    ...maybeCreatePdfComponents(report, relatedEditorial, serviceArgs),
    await maybeCreateContents(report, serviceArgs),
    maybeCreatePaywall(
      serviceArgs,
      report.requiredUserAction,
      ComponentMapper.buildPaywallData(report.paywall, path),
      ComponentMapper.buildPaywallData(report.paywall, path)
    ),
  ]
    .flat()
    .filter(Boolean) as ComponentData[];
};

const maybeCreatePageTitleBlock = (report: Report, canDownload: boolean) => {
  return (
    !report.headerComponent &&
    ReportShowMapper.toPageTitleBlock(report, canDownload)
  );
};

const maybeCreateHeaderComponent = (report: Report) => {
  if (!report.headerComponent) {
    return;
  }
  return ComponentMapper.buildComponent(report.headerComponent);
};

const maybeCreatePdfComponents = (
  report: Report,
  relatedEditorial: any,
  serviceArgs: ServiceArgs
) => {
  if (canShowContent(report, serviceArgs)) {
    return ReportShowMapper.buildPdfView(
      report,
      relatedEditorial,
      serviceArgs.accessToken
    );
  }
  return [];
};

const maybeCreateContents = async (
  report: Report,
  serviceArgs: ServiceArgs
) => {
  if (canShowContent(report, serviceArgs)) {
    return await SectionDataMapper.toSectionComponents(
      report.content,
      report.navigationType,
      serviceArgs
    );
  }
};

const maybeTrackReportView = async (
  report: Report,
  userActions: UserAction[],
  accessToken: string
) => {
  const { marketoFormId, excludedUserAction } = report;
  if (
    marketoFormId &&
    excludedUserAction &&
    !userHasAction(excludedUserAction, userActions)
  ) {
    const response = await GeminiProvider.createTrackForm(
      marketoFormId,
      accessToken
    );
  }
};

const canShowContent = (report: Report, serviceArgs: ServiceArgs) => {
  return Authentication.userHasAction(
    report.requiredUserAction,
    serviceArgs?.userActions
  );
};

export const ReportsShowService: Service = { retrievePageData };
