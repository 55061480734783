import { Typography, type ITypographyProps } from "@lions/ui-components";

// This is a hack to get around the fact that we cant style our Typogrqaphy component with theme colors

type ColorKey = keyof typeof colorMap;

export const TypographyColor = ({
  color,
  ...props
}: ITypographyProps<any> & { color: ColorKey }) => (
  <Typography style={{ color: colorMap[color] }} {...props}>
    {props.children ?? null}
  </Typography>
);

// A copy of our theme colors
const colorMap = {
  primary: "#2E2E2E",
  primaryDark: "white",
  secondary: "#228181",
  accent1: "#50D0D0",
  accent2: "#D7B07D",
  accent3: "#EB9879",
  accent4: "#D1B002",
  accent5: "#2E6C92",
  white: "#FFFFFF",
  offwhite: "#F4F4F4",
  greylight: "#ECECEC",
  greymid: "#D2D2D2",
  greydark: "#707070",
  offblack: "#262626",
  black: "#000000",
  success: "#008810",
  error: "#EB0023",
  none: "transparent",
  initial: "initial",
} as const;
