import { PageTitleBlock } from "@lions/ui-components";
import { buildCta, maybeBuildProfilePrimaryTags } from "./page_title_utils";
import {
  PageTitleBlockComponentProps,
  TagVariant,
} from "./PageTitleBlockComponent.d";

import { buildKey } from "../libs";

export const PageTitleBlockComponent = ({
  pageTitleBlockData: {
    title,
    superText,
    subText,
    awardTags,
    ctas,
    quote,
    profileCard,
  },
  index,
}: PageTitleBlockComponentProps) => (
  <PageTitleBlock
    avatar={
      profileCard && {
        name: profileCard.name ?? "",
        subText: profileCard.subText,
        src: profileCard.media?.href,
        ...maybeBuildProfilePrimaryTags(profileCard),
      }
    }
    ctaButtons={ctas?.map(buildCta)}
    description={quote}
    key={buildKey("pagetitle", index)}
    subtextLeading={superText}
    subtextTrailing={subText}
    tags={
      awardTags &&
      awardTags.map((awardTag) => ({
        variant: awardTag.variant as TagVariant,
        text: awardTag.content,
      }))
    }
    tagsLabel=""
    title={title}
  />
);
