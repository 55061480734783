import { PageData, ComponentData } from "@/renderers";
import { Service, ServiceArgs } from "@/services";
import { ContentfulProvider } from "@/services/providers";

import {
  maybeCreatePaywall,
  stripUndefinedValuesFromObject,
} from "@/services/libs";
import { ContentfulPageResponse } from "@/services/providers/contentful/types";
import {
  ComponentMapper,
  SectionDataMapper,
} from "@/services/providers/contentful/mappers";

const retrievePageData = async (
  serviceArgs: ServiceArgs
): Promise<PageData | null> => {
  const { path } = serviceArgs;

  const pageContent = await ContentfulProvider.getPageContentForSlug(path);
  if (pageContent) {
    const result = {
      title: pageContent.title,
      metaDescription: pageContent.metaDescription,
      slug: pageContent.slug,
      cannonicalUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${pageContent.slug}`,
      metaTitle: pageContent.metaTitle,
      metaImage: pageContent.metaImage,
      components: await buildComponents(pageContent, serviceArgs),
    };
    return stripUndefinedValuesFromObject(result);
  }
  return null;
};

const buildComponents = async (
  {
    requiredUserAction,
    content,
    loggedinPaywall,
    loggedoutPaywall,
    headerComponent,
    announcement,
    navigationType,
  }: ContentfulPageResponse,
  serviceArgs: ServiceArgs
): Promise<ComponentData[]> => {
  const contentComponents = await SectionDataMapper.toSectionComponents(
    content,
    navigationType,
    serviceArgs
  );
  return [
    announcement && ComponentMapper.buildComponent(announcement),
    headerComponent && ComponentMapper.buildComponent(headerComponent),
    contentComponents,
    maybeCreatePaywall(
      serviceArgs,
      requiredUserAction,
      ComponentMapper.buildPaywallData(loggedinPaywall, serviceArgs.path),
      ComponentMapper.buildPaywallData(loggedoutPaywall, serviceArgs.path)
    ),
  ]
    .flat()
    .filter(Boolean) as ComponentData[];
};

export const TemplatePageService: Service = { retrievePageData };
