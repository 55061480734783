import React from "react";
import { SearchDidYouMeanData } from "@/components/components.d";
import { Container } from "@horizon/components";
import { Authentication } from "@/libs";
import { useClientParameters } from "@/libs/ClientParameterProvider";
import { Analytics } from "@/libs/analytics";
import { Api } from "@/libs/api";
import { QueryParams } from "@/libs/QueryParams";
import { TypographyColor } from "@/components/TypographyColor";
import { LinkUnstyled } from "@/components/InLineLink";

export const SearchDidYouMean = ({
  didYouMean,
  altSearch,
  currentSearch,
  queryValue,
}: SearchDidYouMeanData) => {
  const user = Authentication.useAuthenticatedUser();
  const clientParams = useClientParameters();

  const onClickHandler = (searchText: string, selectValue: string) => {
    Analytics.trackSearchTextEntered(clientParams, searchText, user);
    Api.storeUserSeach(searchText);
    QueryParams.replaceAllQueryParams({
      search_text: searchText,
      content_type: selectValue,
    });
  };

  return didYouMean ? (
    <Container data-panda-theme="intelligence">
      <Container>
        {currentSearch && (
          <TypographyColor
            color="primary"
            uppercase
            size="heading-xlarge"
            as="h3"
            className="search-did-you-mean"
          >
            Showing results for{" "}
            <LinkUnstyled
              onClick={(evt: React.MouseEvent) => {
                evt.preventDefault();
                onClickHandler(currentSearch.label, queryValue);
              }}
              href={currentSearch.href}
              key="current"
            >
              {`‘${currentSearch.label}’`}
            </LinkUnstyled>
          </TypographyColor>
        )}

        {altSearch && (
          <TypographyColor color="primary">
            Show result for{" "}
            <LinkUnstyled
              onClick={(evt: React.MouseEvent) => {
                evt.preventDefault();
                onClickHandler(altSearch.label, queryValue);
              }}
              href={altSearch.href}
              key="current"
            >
              {`‘${altSearch.label}’`}
            </LinkUnstyled>{" "}
            instead?
          </TypographyColor>
        )}
      </Container>
    </Container>
  ) : null;
};
