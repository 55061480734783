import React, { CSSProperties, ReactNode } from "react";
import { Link } from "@horizon/components";

interface LinkProps {
  href?: string;
  key: string | number;
  children: ReactNode;
  style?: CSSProperties;
  onClick?: (evt: React.MouseEvent) => void;
  className?: string;
}

export const InLineLink: React.FC<LinkProps> = ({
  href,
  key,
  children,
  style = { fontSize: "18px" },
  onClick,
  className,
}) => (
  <Link
    className={className}
    href={href || ""}
    key={key}
    style={style}
    onClick={onClick}
  >
    {children}
  </Link>
);

export const LinkUnstyled: React.FC<LinkProps> = ({
  href,
  key,
  children,
  onClick,
  className,
}) => (
  <Link
    className={className}
    href={href || ""}
    key={key}
    style={{ fontSize: "inherit" }}
    onClick={onClick}
  >
    {children}
  </Link>
);
