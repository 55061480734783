import { ComponentData, PaginationData } from "@/renderers";

export interface SearchQueryResponse {
  nextResults: ComponentData[];
  pagination?: PaginationData;
}

export interface SearchTextChange {
  searchText: string;
  contentType: string;
}

export interface SuggestionSelectedI {
  suggestionSelected: string;
}

export const SearchEvents = Object.freeze({
  SEARCH_RESULTS: "searchResults",
});

export const SearchTextChange = Object.freeze({
  SEARCH_TEXT_CHANGE: "searchTextChange",
  SUGGESTION_SELECTED: "SUGGESTION_SELECTED",
});

export const SuggestionSelected = Object.freeze({
  SUGGESTION_SELECTED: "SUGGESTION_SELECTED",
});

export const SuggestionSearchAll = Object.freeze({
  SUGGESTION_SEARCH_ALL: "SUGGESTION_SEARCH_ALL",
});
