import { Authentication } from "@/libs";
import { useClientParameters } from "@/libs/ClientParameterProvider";
import { QueryParams } from "@/libs/QueryParams";
import { Analytics } from "@/libs/analytics";
import { SearchBarDataExtend } from "@/renderers";
import { Container } from "@horizon/components";
import { SearchBlock, SelectDropdown, Button } from "@lions/ui-components";
import { SetStateAction, useEffect, useState, useCallback } from "react";
import { Api } from "@/libs/api";
import { type ISelectDropdownOption } from "@/components/components.d";
import { SearchBarSuggestions } from "@/components/SearchBarSuggestions";
import {
  PubSubProvider,
  SearchTextChange,
  SuggestionSearchAll,
  SuggestionSelected,
  SuggestionSelectedI,
} from "@/libs/pubSub";

const mapOptions = (
  currentOptions: ISelectDropdownOption[],
  prop: "label" | "value",
  value: string
) => {
  return currentOptions.map((option) => {
    return {
      ...option,
      checked: option[prop] === value,
    };
  });
};

const mapper: Record<string, string> = {
  inspirations: "INSPIRATION",
  campaigns: "CAMPAIGN",
  talks: "TALK",
  all: "ALL",
  editorials: "EDITORIAL",
};

export const SearchBarComponent = ({
  searchText,
  searchBarLabel,
  suggestions,
  contentType,
  contentLabel,
  searchOptions,
  searchDrawerActive = false,
}: SearchBarDataExtend) => {
  const [text, setSearchText] = useState(searchText || "");
  const [selectOptions, setSelectOptions] = useState(searchOptions);
  const [selectLabel, setSelectLabel] = useState(contentLabel || "All");
  const [selectValue, setSelectValue] = useState(contentType || "all");
  const user = Authentication.useAuthenticatedUser();
  const clientParams = useClientParameters();

  PubSubProvider.useSub(
    SuggestionSelected.SUGGESTION_SELECTED,
    (suggestionSelected: SuggestionSelectedI) => {
      if (suggestionSelected.suggestionSelected) {
        onSearchHandler(suggestionSelected.suggestionSelected);
      }
    }
  );

  PubSubProvider.useSub(
    SuggestionSearchAll.SUGGESTION_SEARCH_ALL,
    (suggestionSelected: SuggestionSelectedI) => {
      if (suggestionSelected.suggestionSelected) {
        setSelectValue("all");
        onSearchHandler(suggestionSelected.suggestionSelected);
      }
    }
  );

  const onSearchHandler = useCallback(
    (label: string): any => {
      const searchText = label || text || "";
      Analytics.trackSearchTextEntered(clientParams, searchText, user);
      Api.storeUserSeach(searchText);
      QueryParams.replaceAllQueryParams({
        search_text: searchText,
        content_type: selectValue,
      });
    },
    [selectValue, text, clientParams, user]
  );

  const onOptionChange = (_name: string, label: string, value: string) => {
    const newOptions = mapOptions(selectOptions, "label", label);
    setSelectOptions(newOptions);
    setSelectLabel(label);
    setSelectValue(value);
  };

  // This effect handles changing the select dropdown options
  useEffect(() => {
    if (searchOptions && text && selectValue !== contentType) {
      onSearchHandler(text);
    }
  }, [searchOptions, selectValue, text, contentType, onSearchHandler]);

  const selectDropdown = searchOptions ? (
    <SelectDropdown
      onOptionChange={onOptionChange}
      label={selectLabel}
      options={selectOptions}
      trigger={
        <Button
          icon={{ position: "end", name: "ChevronDown" }}
          variant="secondary"
        >
          {selectLabel}
        </Button>
      }
    />
  ) : undefined;

  return (
    <Container>
      <SearchBlock
        focus={true}
        initialValue={text}
        placeholder={searchBarLabel}
        optionalElement={selectDropdown}
        onChange={(e: { target: { value: SetStateAction<string> } }) => {
          setSearchText(e.target.value);
          if (searchDrawerActive) {
            PubSubProvider.pubishEvent<SearchTextChange>(
              SearchTextChange.SEARCH_TEXT_CHANGE,
              {
                searchText: e.target.value as SetStateAction<any>,
                contentType: contentType ? mapper[contentType] : "ALL",
              }
            );
          }
        }}
        suggestions={suggestions}
        callback={onSearchHandler}
        searchDrawerElements={
          searchDrawerActive ? <SearchBarSuggestions /> : null
        }
      />
    </Container>
  );
};
