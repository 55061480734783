import { callRestGeminiPost } from "../gemini_provider";

export const getAssetCDNUrl = async (
  assetUrl: string,
  accessToken: string | undefined
): Promise<any> => {
  const response = await callRestGeminiPost(
    "/api/assets/cdn_url",
    { assetUrl: assetUrl },
    accessToken
  );
  const { cdn_url: cdnUrl } = response;

  return cdnUrl;
};
