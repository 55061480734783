import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { CREATE_FORM_TRACKING } from "../queries/form_query";
import { FormTracking } from "../types";

export const createTrackForm = async (
  formId: string,
  accessToken: string
): Promise<FormTracking> => {
  const variables = { formId };

  const response = await callGemini(
    { query: CREATE_FORM_TRACKING, variables: variables },
    accessToken
  );

  return response;
};
