import { RedirectType, permanentRedirect } from "next/navigation";

import { PaywallData } from "@/renderers";
import { ServiceArgs } from "@/services";
import { Authentication } from "@/libs";
import { UserAction } from "@/libs/authentication/user";

export const stripUndefinedValuesFromObject = (data: any): any => {
  if (data === null) {
    return null;
  }
  if (Array.isArray(data)) {
    return data.map(stripUndefinedValuesFromObject);
  } else if (typeof data === "object") {
    const result: any = {};
    for (const key in data) {
      const keyOf = key as keyof object;
      if (data[keyOf] !== undefined) {
        result[keyOf] = stripUndefinedValuesFromObject(data[keyOf]);
      }
    }
    return result;
  } else {
    return data;
  }
};

export const removeNestedNullValues = (obj: any) => {
  for (const key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    } else if (typeof obj[key] === "object") {
      removeNestedNullValues(obj[key]);
    }
  }
  return obj;
};

export const getIdFromSlug = (path: string): string => {
  let slug = path.split("/").pop() || "";
  slug = slug.split("?").shift() || "";
  return slug.split("-").pop() || "";
};

export const getSlugFromPath = (path: string): string => {
  return path.split("/").pop() || "";
};

export const maybeRedirectToSlug = (
  path: string,
  id: string,
  route: string,
  slug: string
) => {
  if (getSlugFromPath(path) === id) {
    permanentRedirect(`${route}/${slug}`, "push" as RedirectType);
  }
};

export const PATHS = {
  ai: "/ai",
  agencies: "/directory/agencies",
  brands: "/directory/brands",
  campaigns: "/work-awards/campaigns",
  claimSubscription: "/claim",
  claimSubscriptionSuccess: "/claim/success",
  claimSubscriptionFailure: "/claim/failed",
  classic: "/classic",
  collections: "/account/collections",
  cookiesPolicy: "/cookies-policy",
  editorial: "/editorial",
  entries: "/work-awards/entries",
  home: "/home",
  inspiration: "/inspiration",
  myProfile: "/account",
  people: "/directory/individuals",
  privacyPolicy: "/privacy-policy",
  results: "/work-awards/results",
  reports: "/reports-rankings/reports",
  root: "/",
  search: "/search",
  sharedCollection: "/shared-collection",
  speakers: "/speakers",
  talks: "/talks-videos/talks",
  termsAndConditions: "/terms-and-conditions",
  theworkLibrary: "/work-awards/library",
  video: "/talks-videos/videos",
  youngEntries: "/young-entries",
};

export const formatNumber = (num: number | undefined | null): string => {
  return num ? num.toLocaleString("en-US") : "0";
};

export const stringToCamelCase = (str: string): string => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase());
};

export const maybeCreatePaywall = (
  serviceArgs?: ServiceArgs,
  action?: (UserAction | null | undefined)[] | UserAction | null,
  loggedInPaywall?: PaywallData,
  loggedOutPaywall?: PaywallData
): PaywallData | undefined => {
  if (!Authentication.userHasAction(action, serviceArgs?.userActions)) {
    if (serviceArgs?.accessToken) {
      return loggedInPaywall || LoggedInPaywallData;
    } else {
      return loggedOutPaywall || LoggedOutPaywallData(serviceArgs?.path || "");
    }
  }
  return undefined;
};

const LoggedInPaywallData: PaywallData = {
  type: "Paywall",
  title: "You need a paid subscription to view this content.",
  html: `
    <p>The Work is home to everything you need to produce culture-shaping creative success, and measurable business growth.</p>
    <p>Home to the world's best work, studies on the industry's hottest topics, and campaign outcomes from the world's biggest brands.</p>
    <p>It's a central hub of creative excellence where you can find insights to apply to your own work – and learn how to Create Better.</p>
  `,
  ctas: [
    {
      type: "Button",
      variant: "secondary",
      label: "Learn More",
      href: "/learn-more",
    },
  ],
};

const LoggedOutPaywallData = (currentPath: string): PaywallData => {
  const encodedPath = Buffer.from(currentPath, "utf-8").toString("base64");
  return {
    type: "Paywall",
    title: "Sign in to view this content or learn more by clicking below",
    html: `
      <p>The Work is home to everything you need to produce culture-shaping creative success, and measurable business growth.</p>
      <p>Home to the world's best work, studies on the industry's hottest topics, and campaign outcomes from the world's biggest brands.</p>
      <p>It's a central hub of creative excellence where you can find insights to apply to your own work – and learn how to Create Better.</p>
    `,
    ctas: [
      {
        type: "Button",
        variant: "secondary",
        label: "Learn More",
        href: "/learn-more",
      },
      {
        type: "Button",
        variant: "primary",
        label: "Sign in",
        href: `/api/auth/login?callback=${encodedPath}`,
      },
    ],
  };
};

export const defaultComponentsPerRow = 4;
export const defaultComponentsPerRowCarousel = [1, 2, 3, 4];
