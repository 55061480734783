import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_SEACH_HISTORIES } from "../queries/search_query";
import { SearchHistory } from "../types";
export const getSearchHistories = async (
  accessToken?: string
): Promise<SearchHistory[]> => {
  const variables = {
    records: 5,
  };
  const response = await callGemini(
    { query: GET_SEACH_HISTORIES, variables: variables },
    accessToken
  );
  if (response) {
    const { getSearchHistories: searchHistories } = response;
    return searchHistories;
  }
  return [];
};
