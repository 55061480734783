import { ButtonData, CardData } from "@/renderers";
import { TagVariant, ButtonIcon, AvatarTag } from "./PageTitleBlockComponent.d";

export const maybeBuildProfilePrimaryTags = (profile: CardData | undefined) => {
  if (!profile || !profile.primaryTags || profile.primaryTags.length === 0) {
    return undefined;
  }
  const tag = profile.primaryTags[0];
  return {
    tag: {
      variant: tag.variant as TagVariant,
      text: tag.content,
    } as AvatarTag,
  };
};

export const buildCta = ({ label, href, variant, icon }: ButtonData) => ({
  href,
  icon: icon && ({ name: icon.name, position: icon.position } as ButtonIcon),
  label,
  variant,
});
