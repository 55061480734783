import { useState, Dispatch, SetStateAction } from "react";

import { CollectionData, SaveCollectionButtonData } from "@/renderers";
import { PageTitleBlock } from "@lions/ui-components";
import { CollectionsModalComponent } from "@/components/Collections/CollectionsModalComponent";

import { buildKey } from "../libs";
import { buildCta, maybeBuildProfilePrimaryTags } from "./page_title_utils";
import {
  ButtonIcon,
  PageTitleBlockComponentProps,
  TagVariant,
  Variant,
} from "./PageTitleBlockComponent.d";

export const PageTitleBlockComponentWithSaveCollections = ({
  pageTitleBlockData: {
    title,
    superText,
    subText,
    awardTags,
    saveCollectionButtonData,
    quote,
    profileCard,
    ctas,
  },
  index,
}: PageTitleBlockComponentProps) => {
  const [isSaveModalOpen, setSaveModalOpen] = useState<boolean>(false);
  const [isCreateModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const contentInCollection = isContentInCollections(saveCollectionButtonData);
  const { collections } = saveCollectionButtonData || {};
  const saveCta = buildSaveCta(
    contentInCollection,
    setSaveModalOpen,
    setCreateModalOpen,
    collections
  );

  return (
    <>
      {saveCollectionButtonData && (
        <CollectionsModalComponent
          saveCollectionButtonData={saveCollectionButtonData}
          saveModalState={[isSaveModalOpen, setSaveModalOpen]}
          updateModalState={[isCreateModalOpen, setCreateModalOpen]}
        />
      )}
      <PageTitleBlock
        avatar={
          profileCard && {
            name: profileCard.name ?? "",
            subText: profileCard.subText,
            src: profileCard.media?.href,
            ...maybeBuildProfilePrimaryTags(profileCard),
          }
        }
        ctaButtons={[saveCta, ...(ctas || []).map(buildCta)]}
        description={quote}
        key={buildKey("pagetitle", index)}
        subtextLeading={superText}
        subtextTrailing={subText}
        title={title}
        tags={
          awardTags &&
          awardTags.map((awardTag) => ({
            variant: awardTag.variant as TagVariant,
            text: awardTag.content,
          }))
        }
        tagsLabel=""
      />
    </>
  );
};

const isContentInCollections = (
  saveCollectionButtonData?: SaveCollectionButtonData
): boolean => {
  const { contentType, contentId, collections } =
    saveCollectionButtonData || {};
  if (collections && contentType && contentId) {
    return collections.some((collection) =>
      isContentInCollection(contentType, contentId, collection)
    );
  }
  return false;
};

const isContentInCollection = (
  contentType: string,
  contentId: string,
  collection: CollectionData
) => {
  if (contentType === "entry") {
    return collection.entryIds.includes(Number(contentId));
  } else if (contentType === "campaign") {
    return collection.campaignIds.includes(Number(contentId));
  }
  return false;
};

const buildSaveCta = (
  contentInCollection: boolean,
  setSaveModalOpen: Dispatch<SetStateAction<boolean>>,
  setCreateModalOpen: Dispatch<SetStateAction<boolean>>,
  collections: CollectionData[] | undefined
) => ({
  label: "Save",
  accessibilityLabel: "save button",
  variant: (contentInCollection ? "primary" : "secondary") as Variant,
  icon: { name: "Heart", position: "end" } as ButtonIcon,
  onClick: () => {
    collections ? setSaveModalOpen(true) : setCreateModalOpen(true);
  },
});
