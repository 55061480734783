export const GET_TEST_FILESPIN_ASSET = `query ($preview: Boolean, $id: String) {
    testFilespinVideoAssetCollection(preview: $preview, where: { sys: { id: $id } }, limit: 1) {
      items {
        instanceName
        assetUrl
        mediaType
      }
    }
  }
 `;
