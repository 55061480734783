import { PageData, ComponentData, ContainerData } from "@/renderers";
import { ServiceArgs, Service } from "..";
import { ContentfulProvider, GeminiProvider } from "../providers";
import { createId } from "@/components/libs";

const retrievePageData = async (
  serviceArgs: ServiceArgs
): Promise<PageData | null> => {
  const { accessToken, path } = serviceArgs;
  if (!accessToken) {
    return {
      title: "",
      metaDescription: "",
      slug: "",
      metaTitle: "",
      cannonicalUrl: "",
      metaImage: {
        url: "",
      },
      components: [],
    };
  }
  const id = path.replace("/filespin/", "");
  const { instanceName, assetUrl, mediaType } =
    await ContentfulProvider.getTestFilesSpinAssetbyId(id);
  const cdnUrl = await GeminiProvider.getAssetCDNUrl(assetUrl, accessToken);

  return {
    title: "",
    metaDescription: "",
    slug: "",
    metaTitle: "",
    cannonicalUrl: "",
    metaImage: {
      url: "",
    },
    components: [
      {
        type: "Container",
        children: [
          {
            type: "SectionBlock",
            title: "Using Cdn",
            subtitle: `url: ${cdnUrl}`,
            content: [
              {
                type: "MediaPlayerData",
                media: {
                  href: cdnUrl,
                  alt: "instanceName",
                  mediaType: mediaType,
                  thumbnail: "",
                },
                title: instanceName,
                category: `Playlist - ${instanceName}`,
                id: instanceName,
              },
            ],
            id: createId(instanceName),
          } as ComponentData,
        ],
      } as ContainerData,
      {
        type: "Container",
        children: [
          {
            type: "SectionBlock",
            title: "Using Filespin",
            subtitle: `url: ${assetUrl}`,
            content: [
              {
                type: "MediaPlayerData",
                media: {
                  href: assetUrl,
                  alt: "instanceName",
                  mediaType: mediaType,
                  thumbnail: "",
                },
                title: instanceName,
                category: `Playlist - ${instanceName}`,
                id: instanceName,
              },
            ],
            id: createId(instanceName),
          } as ComponentData,
        ],
      },
    ],
  };
};

export const FilespinTestAssetService: Service = { retrievePageData };
