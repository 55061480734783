import { ComponentData, PageData } from "@/renderers";
import { GeminiProvider } from "@/services/providers";
import { ServiceArgs, Service } from "@/services/";
import {
  PATHS,
  SITE_TITLE,
  buildSectionData,
  getIdFromSlug,
  maybeCreatePaywall,
  stripUndefinedValuesFromObject,
} from "@/services/libs";
import { Campaign, Collection } from "@/services/providers/gemini/types";
import { CampaignShowMapper } from "./campaign_show_mapper";

const retrievePageData = async (
  serviceArgs: ServiceArgs
): Promise<PageData | null> => {
  const { path, accessToken } = serviceArgs;
  if (path.startsWith(`${PATHS.campaigns}/`)) {
    const id = getIdFromSlug(path);
    const campaign = await GeminiProvider.getCampaignById(id, accessToken);
    const collections = await GeminiProvider.getCollections(accessToken);
    const relatedCampaigns = await GeminiProvider.getRelatedCampaigns(
      id,
      accessToken
    );
    if (campaign) {
      const result = {
        title: `${campaign.title} | Campaign | ${SITE_TITLE}`,
        metaDescription: campaign.title,
        slug: `${PATHS.campaigns}${campaign.slug}`,
        cannonicalUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${PATHS.campaigns}/${campaign.slug}`,
        metaTitle: campaign.title,
        metaImage: { url: campaign.assets[0]?.thumbnailUrl || "" },
        components: buildComponents(
          campaign,
          relatedCampaigns,
          collections,
          serviceArgs
        ),
      };
      return stripUndefinedValuesFromObject(result);
    }
  }
  return null;
};

const buildComponents = (
  campaign: Campaign,
  relatedCampaigns: [Campaign] | [],
  collections: Collection[],
  serviceArgs: ServiceArgs
): ComponentData[] => {
  const { userActions } = serviceArgs;
  const galleryCarousel = CampaignShowMapper.maybeToGalleryCarousel(
    campaign,
    userActions
  );
  return [
    CampaignShowMapper.toTitleData(campaign, collections),
    galleryCarousel ? galleryCarousel : {},
    buildSectionData([CampaignShowMapper.toTabContainer(campaign)]),
    CampaignShowMapper.toRelatedCampaigns(relatedCampaigns),
    maybeCreatePaywall(serviceArgs, campaign.requiredUserActions),
  ].filter(Boolean) as ComponentData[];
};

export const CampaignShowService: Service = { retrievePageData };
