import {
  ContentfulPageResponse,
  Navigation,
  Report,
  TestFilespinVideoAsset,
} from "./types";
import { NAVIGATION_CONTAINER_BY_ID } from "./queries/navigation_queries";
import { PAGE_BY_SLUG_QUERY } from "./queries/page_queries";
import { REPORT_BY_ID_QUERY } from "./queries/report_queries";
import { SEARCH_META_DATA_QUERY } from "./queries/search_queries";
import { logger } from "@/libs/Logger";
import { ErrorHandler } from "@/libs";
import { SECTION_BY_ID } from "./queries/section_queries";
import { GET_TEST_FILESPIN_ASSET } from "./queries/test_filespin_queries";

type ContentfulQueryProps = {
  query: string;
  variables: object;
};

const getAllowPreviewEnv = () => process.env.ALLOW_PREVIEW === "true";

const getTestFilesSpinAssetbyId = async (
  id: string
): Promise<TestFilespinVideoAsset> => {
  const results = await postQuery({
    query: GET_TEST_FILESPIN_ASSET,
    variables: {
      navigationId: id,
      locale: "en-US",
      preview: getAllowPreviewEnv(),
    },
  });
  const {
    data: {
      testFilespinVideoAssetCollection: { items: items },
    },
  } = results;
  return items[0];
};

const getNavigationById = async (
  id: string | undefined
): Promise<Navigation | null> => {
  if (id === undefined) {
    ErrorHandler.noticeError(
      "ID Is undefined when getting navigation.  Have you set your env var?"
    );
    return null;
  }
  const results = await postQuery({
    query: NAVIGATION_CONTAINER_BY_ID,
    variables: {
      navigationId: id,
      locale: "en-US",
      preview: getAllowPreviewEnv(),
    },
  });
  if (results) {
    const {
      data: {
        navigationContainer: { navigationItemsCollection },
      },
    } = results;
    return navigationItemsCollection;
  }
  return null;
};

const getSearchMetaData = async (): Promise<string[]> => {
  const results = await postQuery({
    query: SEARCH_META_DATA_QUERY,
    variables: {
      preview: getAllowPreviewEnv(),
      id: process.env.CONTENTFUL_SEARCH_DATA_ID,
    },
  });
  return results ? results.data.searchMetaData.suggestions : [];
};

const getPageContentForSlug = async (
  path: string
): Promise<ContentfulPageResponse | undefined> => {
  const slug = path.split("?")[0]; // drop the query string if it exists
  const {
    data: {
      pageCollection: { items },
    },
  } = await postQuery({
    query: PAGE_BY_SLUG_QUERY,
    variables: { slug, preview: getAllowPreviewEnv() },
  });

  if (items.length !== 1) return undefined;

  const [page] = items;
  const sectionIds = page.content.items.map((item: { sys: { id: any } }) => {
    return item.sys.id;
  });
  const content = await sectionsByIds(sectionIds, getAllowPreviewEnv());

  return { ...page, content: content };
};

const getReportContentById = async (
  id: string
): Promise<Report | undefined> => {
  const {
    data: {
      reportCollection: { items },
    },
  } = await postQuery({
    query: REPORT_BY_ID_QUERY,
    variables: { id, preview: getAllowPreviewEnv() },
  });

  if (items.length !== 1) return undefined;

  const [page] = items;
  const sectionIds = page.content.items.map((item: { sys: { id: any } }) => {
    return item.sys.id;
  });
  const content = await sectionsByIds(sectionIds, getAllowPreviewEnv());

  return { ...page, content: content };
};

const sectionsByIds = async (sectionIds: String[], preview: boolean) => {
  const responses = await Promise.all(
    sectionIds.map((id) => {
      return postQuery({
        query: SECTION_BY_ID,
        variables: { id: id, preview: preview },
      });
    })
  );

  return responses
    .map((response) => {
      const {
        data: {
          sectionCollection: { items: section },
        },
      } = response;
      return section;
    })
    .flat();
};

const fetchAPI = async (options: RequestInit | undefined) => {
  const space = process.env.CONTENTFUL_SPACE_ID || "";
  const environment = process.env.CONTENTFUL_ENVIRONMENT || "master";

  const url = `https://graphql.contentful.com/content/v1/spaces/${space}/environments/${environment}`;
  try {
    const result = await fetch(url, options);
    const data = await result.json();
    if (data.errors) {
      ErrorHandler.noticeError(data.errors);
      return null;
    }
    return data;
  } catch (err) {
    logger.info(err);
  }
};

const postQuery = async (queryProps: ContentfulQueryProps): Promise<any> => {
  const accessToken = getAccessToken(queryProps);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(queryProps),
  };
  return fetchAPI(options);
};

const getAccessToken = ({ variables }: ContentfulQueryProps) => {
  const { preview } = variables as any;
  if (preview) {
    return process.env.CONTENTFUL_PREVIEW_API_TOKEN;
  }
  return process.env.CONTENTFUL_ACCESS_TOKEN;
};

export const ContentfulProvider = {
  getPageContentForSlug,
  getNavigationContainerById: getNavigationById,
  getReportContentById,
  getSearchMetaData,
  getTestFilesSpinAssetbyId,
};
