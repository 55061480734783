import {
  Award,
  AwardLevel,
  awardLevelVariantMapping,
  ClassicAwardLevel,
  classicAwardLevelVariantMapping,
  FilterOption,
} from "@/services/providers/gemini/types";
import {
  AwardsBlockData,
  AwardTagData,
  AwardTagVariant,
  ComponentData,
  TagData,
} from "@/renderers";
import { camelCase } from "lodash";
import { buildContainerData, pluralize, TEXT_CONSTS } from "@/services/libs";

export const awardLevelToVariant = (level: AwardLevel): AwardTagVariant => {
  return awardLevelVariantMapping[level];
};
export const classicAwardLevelToVariant = (
  level: ClassicAwardLevel
): AwardTagVariant => {
  return classicAwardLevelVariantMapping[level];
};

export const awardCountsToTags = (
  awardCount: number,
  shortlistCount: number
): AwardTagData[] => {
  const tags: AwardTagData[] = [];
  if (awardCount && awardCount > 0) {
    tags.push({
      type: "Tag",
      content: `${awardCount} Awards`,
      variant: "gold",
      component: "Award",
    });
  }
  if (shortlistCount && shortlistCount > 0) {
    tags.push({
      type: "Tag",
      content: `${shortlistCount} Shortlists`,
      variant: "shortlist",
      component: "Award",
    });
  }
  return tags;
};

export const awardToTagData = (award: Award, label?: string): AwardTagData => {
  const result = {
    type: "Tag",
    component: "Award",
    content: label || award.levelLabel,
    variant: awardLevelToVariant(award.level),
  } as AwardTagData;
  return result;
};

export const awardToTagDataWithCount = (award: Award): AwardTagData =>
  awardToTagData(award, `${award.count} ${award.levelLabel}`);

export const awardToFestivalTagData = (
  award: Award,
  festival: { code: string; name: string }
): AwardTagData | TagData => {
  const result =
    award.level === ("shortlist" as AwardLevel)
      ? ({
          type: "Tag",
          component: "Award",
          content: award.levelLabel,
          variant: awardLevelToVariant(award.level),
        } as AwardTagData)
      : ({
          type: "Tag",
          component: "Festival",
          content: award.levelLabel,
          variant: camelCase(festival.name),
        } as TagData);
  return result;
};

export const awardsToFestivalTagData = (
  awardCount: number | null,
  festivalName: string
): TagData => {
  const tagData =
    awardCount && awardCount > 0
      ? ({
          type: "Tag",
          component: "Festival",
          content: `${awardCount} ${festivalName} ${pluralize(
            awardCount,
            TEXT_CONSTS.AWARD
          )}`,
          variant: camelCase(festivalName),
        } as TagData)
      : ({
          type: "Tag",
          component: "Award",
          content: `Shortlisted ${festivalName}`,
          variant: "shortlist",
        } as TagData);
  return tagData;
};

export const classicToAwardTagData = (
  award: ClassicAwardLevel | string
): AwardTagData => {
  const classic_award = (award || "Shortlist") as ClassicAwardLevel;
  let result: AwardTagData = {
    type: "Tag",
    content: award,
    component: "Award",
    variant: classicAwardLevelToVariant(classic_award),
  };
  return result as AwardTagData;
};

const filterOptionToAwardsBlock = (filter: FilterOption): AwardsBlockData => ({
  type: "AwardsBlock",
  awardTags: filter.values
    .filter((value) => value !== null)
    .map((value) => ({
      variant: awardLevelVariantMapping[value.key as AwardLevel],
      content: `${value.count} ${value.label}`,
    })) as AwardTagData[],
});

export const toAwardedAwardsBlock = (
  awardsCount: FilterOption
): ComponentData =>
  buildContainerData([filterOptionToAwardsBlock(awardsCount) as ComponentData]);

export const awardTagLevelValues: Record<AwardTagVariant, number> = {
  grandPrix: 6,
  titanium: 5,
  gold: 4,
  silver: 3,
  bronze: 2,
  shortlist: 1,
};

export const sortAwards = (awards: Award[]): Award[] => {
  return awards.sort(sortByAwardLevel);
};

export const sortByAwardLevel = (a: Award, b: Award): number => {
  const aVariant = awardLevelVariantMapping[a.level];
  const bVariant = awardLevelVariantMapping[b.level];
  return awardTagLevelValues[bVariant] - awardTagLevelValues[aVariant];
};
