import { gql } from "graphql-request";

export const CREATE_FORM_TRACKING = gql`
  mutation createFormTracking($formId: String!) {
    createFormTracking(formId: $formId) {
      userId
      formId
    }
  }
`;
