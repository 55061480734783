import { ParsedQuery, QueryParams } from "@/libs/QueryParams";
import { callClientApi, callGetApi } from "@/libs/client_api_caller";

export const searchContent = async ({
  parsedSearchQuery,
}: {
  parsedSearchQuery?: ParsedQuery;
}) => {
  const queryString = QueryParams.queryToString(parsedSearchQuery);

  const response = await callGetApi(`/api/search?${queryString}`);
  if (response.ok) {
    return response.json();
  }
};

export const getSearchSuggestions = async ({
  searchText,
  contentTypes,
}: {
  searchText: string;
  contentTypes: string[];
}) => {
  const response = await callClientApi(`/api/search/suggestion`, {
    searchText,
    contentTypes,
  });
  if (response.ok) {
    return await response.json();
  }
};

export const getSearchHistory = async () => {
  const response = await callGetApi(`/api/search/history`);
  if (response.ok) {
    return await response.json();
  }
};
