import { useCallback, useEffect, useRef } from "react";

export const useDebounce = (callback: any, delay: number) => {
  const handlerRef = useRef<NodeJS.Timeout | null>(null);
  const debouncedCallback = useCallback(
    (...args: any[]) => {
      if (handlerRef.current) {
        clearTimeout(handlerRef.current);
      }
      handlerRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );
  useEffect(() => {
    return () => {
      if (handlerRef.current) {
        clearTimeout(handlerRef.current);
        handlerRef.current = null;
      }
    };
  }, []);
  return debouncedCallback;
};
