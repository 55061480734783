import { AuthenticatedUser } from "@/libs";
import { UserAction } from "@/libs/authentication/user";
import { PageData } from "@/renderers";

export interface ServiceArgs {
  path: string;
  accessToken?: string;
  queryParams?: string;
  user?: AuthenticatedUser;
  userActions?: UserAction[];
}

export interface Service {
  retrievePageData(args: ServiceArgs): Promise<PageData | null>;
}

export * from "./talks-videos/talk_show/talk_show.service";
export * from "./talks-videos/talks_listing/talks_listing.service";
export * from "./work-awards/entry_show/entry_show_service";
export * from "./work-awards/campaign_show/campaign_show_service";
export * from "./navigation/navigation_service";
export * from "./template_page/template_page.service";
export * from "./talks-videos/classic_show/classic_show.service";
export * from "./directory/brand_show/brand_show_service";
export * from "./directory/brand_listing/brand_listing_service";
export * from "./inspiration/inspiration_show_service";
export * from "./directory/agency_listing/agency_listing_service";
export * from "./directory/agency_show/agency_show_service";
export * from "./directory/individual_show/individual_show_service";
export * from "./account/account_show/account_show_service";
export * from "./account/create_profile/create_profile_service";
export * from "./account/collection_show/collection_show_service";
export * from "./search/search.service";
export * from "./directory/individual_listing/individual_listing_service";
export * from "./talks-videos/video_show/video_show_service";
export * from "./young-entries/young_entry_show_service";
export * from "./talks-videos/classic_listing/classic_listing_service";
export * from "./reports-rankings/reports-show/reports_show_service";
export * from "./filespin/test_asset_service";
