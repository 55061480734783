import { ParsedQuery } from "@/libs/QueryParams";
import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_SEARCH_COUNTS } from "@/services/providers/gemini/queries/search_query";
import { SearchCounts } from "@/services/providers/gemini/types";

export const getSearchCounts = async (
  queryParams: ParsedQuery,
  accessToken?: string
): Promise<SearchCounts> => {
  const response = await callGemini(
    {
      query: GET_SEARCH_COUNTS,
      variables: { searchText: queryParams?.search_text },
    },
    accessToken
  );
  const { getSearchCounts: counts } = response;

  return counts;
};
