import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_SEARCH_SUGGESTIONS } from "@/services/providers/gemini/queries/search_query";
import { SearchSuggestionResponse } from "@/services/providers/gemini/types";

export const getSearchSuggestions = async (
  searchText: string,
  contentTypes: string[],
  accessToken?: string
): Promise<SearchSuggestionResponse> => {
  const { getSearchSuggestions: searchSuggestions } = await callGemini(
    {
      query: GET_SEARCH_SUGGESTIONS,
      variables: { searchText, contentTypes },
    },
    accessToken
  );
  return searchSuggestions;
};
