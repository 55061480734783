import {
  ComponentData,
  GalleryCarouselData,
  PageTitleBlockData,
  SaveCollectionButtonData,
  TabContainerData,
  TabData,
  TableData,
  TableDataNew,
} from "@/renderers";
import {
  Award,
  AwardLevel,
  CampaignOfExecution,
  Collection,
  Entry,
} from "@/services/providers/gemini/types";
import {
  EXECUTION_CELL_MAPPING,
  EXECUTION_HEADER_STRINGS,
  listToCommaSeparatedString,
  typeFromAsset,
} from "../../libs";
import { ComponentGrouping } from "@/services/providers/contentful/types";
import { GeminiMapper } from "@/services/providers/gemini/mappers";
import { buildContainerData, buildSpacerData, PATHS } from "@/services/libs";
import {
  awardLevelToVariant,
  awardToTagData,
} from "@/services/libs/award_utils";
import { UserAction } from "@/libs/authentication/user";

const toTitleData = (
  entry: Entry,
  collections?: Collection[]
): PageTitleBlockData => {
  const { brand, campaign, company, entryType, title, year } = entry;
  let awardTags = {};
  if (entryType.award !== null) {
    awardTags = { awardTags: [awardToTagData(entryType.award as Award)] };
  }
  return {
    type: "PageTitleBlock",
    title: title,
    superText: `${entryType?.name} > ${entryType?.section?.name}`,
    subText:
      listToCommaSeparatedString([company?.name, company?.town]) +
      ` / ` +
      brand?.name +
      " / " +
      year,
    ...awardTags,
    ctas: [
      {
        type: "Button",
        variant: "secondary",
        icon: { name: "Layout", position: "end" },
        label: "Campaign",
        href: `${PATHS.campaigns}/${campaign?.slug}`,
      },
    ],
    saveCollectionButtonData: toCollectionsSaveButton(entry, collections),
  };
};

const toGalleryCarousel = (
  { assets }: Entry,
  userActions?: UserAction[]
): GalleryCarouselData => ({
  type: "GalleryCarousel",
  userActions,
  media: assets.map((asset) => ({
    href: asset.fullUrl || "",
    alt: asset.label || "",
    mediaType: typeFromAsset(asset),
    thumbnail: asset.thumbnailUrl,
    id: asset.id as number,
    downloadable: asset.downloadable,
  })),
});

const maybeToGalleryCarousel = (
  entry: Entry,
  userActions?: UserAction[]
): GalleryCarouselData | null => {
  return entry.assets.length ? toGalleryCarousel(entry, userActions) : null;
};

const createExecutionRowCells = (
  execution: CampaignOfExecution,
  rowIndex: number,
  slug: string | null
): any[] => {
  return EXECUTION_HEADER_STRINGS.map((string) => {
    if (string === "Execution") {
      if (slug !== execution.slug) {
        return {
          highlight: true,
          label: {
            copy: execution.title,
            href: `${PATHS.entries}/${execution.slug}`,
            size: "small",
          },
          type: "link",
          alignment: "left",
        };
      } else {
        return {
          highlight: true,
          label: {
            copy: execution.title,
            size: "small",
          },
          type: "label",
          alignment: "left",
        };
      }
    }

    if (EXECUTION_CELL_MAPPING[string]) {
      return {
        label: {
          copy: Reflect.get(execution, EXECUTION_CELL_MAPPING[string]),
          size: "small",
        },
        type: "label",
        alignment: "left",
      };
    }
    const isFirstRowAndPrizeLabel = rowIndex === 0 && execution.prizeLevel;

    return {
      tag: {
        copy: isFirstRowAndPrizeLabel ? execution.prizeLabel : "",
        variant: isFirstRowAndPrizeLabel
          ? awardLevelToVariant(execution.prizeLevel as AwardLevel)
          : "",
      },
      type: "tag",
      alignment: "left",
    };
  });
};

const createExecutionTableRows = (
  campaignsOfExecution: CampaignOfExecution[],
  slug: string | null
): any[] => {
  return campaignsOfExecution.map(
    (
      campaign,
      rowIndex
    ): {
      // correct types not exported
      cells: any[];
    } => {
      return {
        cells: createExecutionRowCells(campaign, rowIndex, slug),
      };
    }
  );
};

export const toExecutionTab = (
  campaignsOfExecution: CampaignOfExecution[],
  slug: string | null
): TableDataNew[] => {
  return [
    {
      type: "TableNew",
      title: "Executions",
      tableData: {
        headers: EXECUTION_HEADER_STRINGS.map((headerStr) => ({
          alignment: "left",
          label: {
            copy: headerStr,
          },
        })),
        rows: createExecutionTableRows(campaignsOfExecution, slug),
      },
    },
  ];
};

const toTabContainer = (entry: Entry): TabContainerData => {
  const { campaignsOfExecution, slug } = entry;
  let tabs: TabData[];
  tabs = [
    {
      type: "Tab",
      title: "Overview",
      label: "Overview",
      components: [buildContainerData(toOverviewTab(entry))],
    },
    {
      type: "Tab",
      title: "Credits",
      label: "credits",
      components: [buildContainerData([buildSpacerData(toCreditsTab(entry))])],
    },
  ];
  if (campaignsOfExecution?.length) {
    const executionTab: TabData = {
      type: "Tab",
      title: "Executions",
      label: "Executions",
      components: [
        buildContainerData(toExecutionTab(campaignsOfExecution, slug)),
      ],
    };
    tabs.splice(1, 0, executionTab);
  }

  return {
    type: "TabContainer",
    reloadOnTabChange: false,
    tabs: tabs,
  };
};

const toOverviewTab = ({ caseStudy }: Entry): ComponentData[] => {
  const content = caseStudy?.reduce(
    (accumulator: any[], entry) => {
      accumulator.push({
        type: "Caption",
        content: entry?.question,
        size: "medium",
      });
      accumulator.push({
        type: "Markdown",
        html: entry.response
          ?.split("\n")
          .filter(Boolean)
          .map((str) => `<p>${str}</p>`)
          .join(""),
        copySize: "medium",
      });
      return accumulator;
    },
    [
      {
        type: "HeroTitle",
        content: "OVERVIEW",
        size: "medium",
      },
    ]
  );

  return [
    {
      type: "Spacer",
      size: "medium",
      content,
    } as ComponentData,
  ];
};

const toCreditsTab = (entry: Entry): TableData[] => {
  const companies = entry.credits?.companies?.map((company) => {
    return {
      cells: [
        {
          label: {
            copy: company.name,
            href: company.slug ? `${PATHS.agencies}/${company.slug}` : "",
            size: "small",
          },
          type: company.slug ? "link" : "label",
        },
        {
          label: {
            copy: company.location,
            size: "small",
          },
        },
        {
          label: {
            copy: company.role,
            size: "small",
          },
        },
      ],
    };
  });
  const people = entry.credits?.people?.map((person) => {
    return {
      cells: [
        {
          label: {
            copy: person.name,
            href: person.slug ? `${PATHS.people}/${person.slug}` : "",
            size: "small",
          },
          type: person.slug ? "link" : "label",
        },
        {
          label: {
            copy: person.company,
            size: "small",
          },
        },
        {
          label: {
            copy: person.role,
            size: "small",
          },
        },
      ],
    };
  });

  return [
    entry.credits?.companies && {
      type: "Table",
      title: "Companies",
      headers: [
        { label: { copy: "Company" } },
        { label: { copy: "Location" } },
        { label: { copy: "Role" } },
      ],
      rows: companies,
    },
    entry.credits?.people && {
      type: "Table",
      title: "People",
      headers: [
        { label: { copy: "Name" } },
        { label: { copy: "Company" } },
        { label: { copy: "Role" } },
      ],
      rows: people,
    },
  ].filter(Boolean) as TableData[];
};

const toFooterCarousels = (componentGroupings: ComponentGrouping[]) => {
  return componentGroupings.map(GeminiMapper.toComponentGroupingData);
};

const toCollectionsSaveButton = (
  entry: Entry,
  collections?: Collection[]
): SaveCollectionButtonData | undefined => {
  if (!entry.saveable) return;
  return {
    type: "SaveCollectionButton",
    collections: collections?.map(GeminiMapper.toCollectionData),
    contentType: "entry",
    contentId: entry.id.toString(),
  };
};

export const EntryShowMapper = {
  toTitleData,
  toGalleryCarousel,
  maybeToGalleryCarousel,
  toTabContainer,
  toFooterCarousels,
};
